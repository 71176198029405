.slick-track {
	display: flex !important;
}

.slick-track .slick-slide {
	/* display: flex !important; */
	justify-content: center;
	height: auto !important;
	width: 100vw !important;
	overflow: hidden;
}

.slick-next {
	right: 0 !important;
}

@media screen and (max-width: 400px) {
	.slick-slide img {
		height: 25vh !important;
		width: 100% !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 840px) {
	.slick-slide img {
		height: 50vh !important;
		width: 100% !important;
	}
}

@media screen and (min-width: 840px) and (max-width: 1024px) {
	.slick-slide img {
		height: 70vh !important;
		width: 100% !important;
	}
}

@media screen and (min-width: 1024px) {
	.slick-slide img {
		height: 80vh !important;
		width: 100% !important;
	}
}