@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);

	html,
	body {
		@apply font-primary;
	}

	canvas {
		display: inline-block;
	}
}

.react-datepicker-popper {
	z-index: 100 !important;
}
